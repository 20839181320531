import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import MainSectionZakaznickaZona from "../components/zakaznicka-zona/mainSection.js";
import ReCAPTCHA from "react-google-recaptcha"; // Import the react-google-recaptcha library

import "../styles/kontakt.scss";

const Kontakt = () => {
  const [value, setValue] = useState({});
  const [serverResponse, setServerResponse] = useState("");
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(""); // Store reCAPTCHA response

  useEffect(() => {
    let timeout;
    if (serverResponse) {
      timeout = setTimeout(() => {
        setServerResponse("");
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [serverResponse]);

  // Listen to form changes and save them.
  function handleChange(e) {
    value[e.target.name] = e.target.value;
    setServerResponse(``);
    setValue({ ...value });
  }

  // When the form is submitted, send the form values
  async function onSubmit(e) {
    e.preventDefault();
    if (checked) {
      const response = await window.fetch(`/api/send-email`, {
        method: `POST`,
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ ...value, recaptchaValue }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setServerResponse(responseData.message);
        setValue({});
        setError(false);
        setChecked(false);
      } else {
        console.error("Error submitting the form");
        setServerResponse(
          "Chyba pri odosielaní formulára. Kontaktujete nás na adrese info@obs.sk."
        );
      }
    } else {
      setError(true);
    }
  }

  // Function to handle reCAPTCHA response
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <Layout pageName="kontakt">
      <SEO
        title="Kontakt"
        description="Či už máte otázku, potrebujete konzultáciu alebo chcete spolupracovať na novom projekte, sme tu pre vás. Dajte nám vedieť, ako vám môžeme pomôcť, a my sa ozveme čo najskôr."
      />
      <MainSectionZakaznickaZona
        heading="&#128640; Spojte sa s nami a objavte nové možnosti!"
        textHtml={
          <b>
            Či už máte otázku, potrebujete konzultáciu alebo chcete
            spolupracovať na novom projekte, sme tu pre vás.
            <br />
            Dajte nám vedieť, ako vám môžeme pomôcť, a my sa ozveme čo najskôr.
          </b>
        }
      />
      <section id="contact">
        <div className="contactInfo">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              width={30}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
              />
            </svg>

            <a href="tel:+421 902 085 900">+421 902 085 900</a>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              width={30}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
              />
            </svg>
            <a href="mailto: info@obs.sk">info@obs.sk</a>
          </div>
        </div>
        <div className="formContainer">
          <form onSubmit={onSubmit} method="POST" action="/api/send-email">
            <div className="grid">
              <div className="input_text_cont">
                <span className="form_control_wrap">
                  <input
                    type="text"
                    name="name"
                    className="form-controll"
                    placeholder="Napíšte vaše meno..."
                    onChange={handleChange}
                    value={value[`name`] || ``}
                    required
                  />
                </span>
                <label className="input_text_label">Meno a priezvisko *</label>
              </div>

              <div className="input_text_cont">
                <span className="form_control_wrap">
                  <input
                    type="email"
                    name="email"
                    className="form-controll"
                    placeholder="@"
                    onChange={handleChange}
                    value={value[`email`] || ``}
                    required
                  />
                </span>
                <label className="input_text_label">Váš e-mail *</label>
              </div>

              <div className="input_text_cont">
                <span className="form_control_wrap">
                  <input
                    type="text"
                    name="phone"
                    className="form-controll"
                    onChange={handleChange}
                    value={value[`phone`] || ``}
                    placeholder="+421..."
                  />
                </span>
                <label className="input_text_label">
                  Telefón<span> - nepovinné</span>
                </label>
              </div>

              <div className="textarea_text_cont">
                <span className="form_control_wrap">
                  <textarea
                    name="message"
                    cols="30"
                    rows="5"
                    className="form-controll"
                    placeholder="Dobrý deň, mám záujem o…"
                    onChange={handleChange}
                    value={value[`message`] || ``}
                    required
                  ></textarea>
                </span>
                <label className="input_text_label">Vaša správa *</label>
              </div>
              <div className="checkboxWrapper">
                <span className="form_control_wrap">
                  <span className="acceptance">
                    <span className="list-item">
                      <label>
                        <input
                          type="checkbox"
                          name="suhlas"
                          onChange={() => setChecked(!checked)}
                          checked={checked ? true : false}
                        />
                        <span
                          className="list-item-label"
                          style={error ? { color: "red" } : null}
                        >
                          Súhlasím so spracovaním{" "}
                          <a href="/files/gdpr.pdf" target="_blank">
                            Osobných údajov podľa GDPR
                          </a>{" "}
                          (povinné)
                        </span>
                      </label>
                    </span>
                  </span>
                </span>
              </div>

              {/* Add the reCAPTCHA v2 component */}
              <div className="recaptchaWrapper">
                <ReCAPTCHA
                  sitekey="6Ldz9DQoAAAAAMtpaNruIUwKLVlklx-j5l9dKSfd" // Replace with your reCAPTCHA v2 site key
                  onChange={handleRecaptchaChange} // Handle reCAPTCHA response
                />
              </div>

              <div className="btnWrapper">
                <input type="submit" value="Odoslať" />
              </div>
            </div>
            {serverResponse ? (
              <div className="response-output" role="alert" aria-hidden="true">
                {serverResponse}
              </div>
            ) : null}
          </form>
        </div>
      </section>
    </Layout>
  );
};

export default Kontakt;
