import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
//swiper plugin na mobile
import { Swiper, SwiperSlide } from "swiper/react";

const MainSectionZakaznickaZona = (props) => {
  return (
    <section className="section-blog head-section" id={props.id}>
      <div className="banner-blog">
        <h1>{props.heading}</h1>
        {props.subHeading ? (
          <h2 className="subpage-subheading">{props.subHeading}</h2>
        ) : null}

        <h2 className="subpage-desc">{props.textHtml}</h2>
      </div>
    </section>
  );
};

export default MainSectionZakaznickaZona;
